<template>
  <transition name="modal-show">
    <section v-if="show" class="marketplace-modal">
      <div class="marketplace-modal__content">
        <p>{{ $translate("modal.title") }} <b>{{ store_domain }}</b> {{ $translate("modal.store") }}</p>

        <div class="marketplace-modal__settings">
          <span>{{ $translate("modal.topic_message") }}: </span>
          <select v-model="selected_subject">
            <option
              v-for="subject in message_subjects"
              :key="subject"
              :value="subject"
            >{{ subject }}</option>
          </select>
        </div>

        <div v-if="product_data.id" class="marketplace-modal__product">
          <img :src="get_correct_first_image(product_data)" alt="Product image">
          <div>
            {{ get_correct_translation_value(product_data, "title", [selected_language]) }}
          </div>
        </div>

        <div v-if="show_error" class="marketplace-modal__error">
          {{ $translate("modal.error_message") }}
        </div>

        <textarea v-model="message_text" />

        <div class="marketplace-modal__buttons">
          <span v-if="store_domain">
            {{ $translate("modal.visit_store") }}:
            <a class="marketplace-link" :href="`http://${store_domain}`" target="_blank" rel="noopener noreferrer">{{ store_domain }}</a>
          </span>
          <div class="marketplace-button marketplace-button--basic" @click="send_message">
            {{ $translate("send") }}
          </div>
          <div class="marketplace-button marketplace-button--black-and-white" @click="$emit('close_modal')">
            {{ $translate("close") }}
          </div>
        </div>
      </div>
    </section>
  </transition>
</template>

<script>
import { default_language } from "../../../Shared/constants/other"
import get_correct_first_image from "../../../Shared/methods/get_correct_first_image"
import get_correct_translation_value from "../../../Shared/methods/get_correct_translation_value"

export default {
  props: {
    contact_email: {
      type: String,
      required: false,
      default: ""
    },
    store_domain: {
      type: String,
      required: false,
      default: ""
    },
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    product_data: {
      type: Object,
      required: false,
      default: () => ({})
    },
    selected_language: {
      type: String,
      required: false,
      default: default_language
    }
  },
  data() {
    return {
      message_text: "",
      selected_subject: "",
      show_error: false,
      message_subjects: [
        this.$translate ? this.$translate("modal.subjects.general") : "",
        this.$translate ? this.$translate("modal.subjects.product") : "",
        this.$translate ? this.$translate("modal.subjects.shipping") : "",
        this.$translate ? this.$translate("modal.subjects.returns") : "",
      ]
    }
  },
  methods: {
    get_correct_first_image,
    get_correct_translation_value,
    send_message() {
      if (!this.selected_subject || !this.message_text) return this.show_error = true

      this.show_error = false
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_marketplace-constants" as *;
  @use "../../../../styles/marketplace" as *;

  .marketplace-modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    font-weight: 400;
    background-color: rgba($grey--darkest, 0.5);
    z-index: 100;
    text-align: left;

    @media (max-width: $tablet) {
      font-size: $font-size--medium;
    }

    &__content {
      position: absolute;
      padding: $spacing--medium;
      left: 50vw;
      top: 50vh;
      background: $pure-white;
      transform: translateY(-50%) translateX(-50%);
      border-radius: $border-radius;

      @media (max-width: $tablet) {
        padding: $spacing--small;
      }

      p {
        font-size: 18px;
        margin: 0 0 $spacing--medium;

        @media (max-width: $tablet) {
          font-size: $font-size--medium;
          margin: 0 0 $spacing--small;
        }
      }

      textarea {
        width: 100%;
        min-width: 300px;
        min-height: 200px;
        padding: $spacing--small;
      }
    }

    &__buttons {
      margin-top: $spacing--small;

      @media (max-width: $tablet) {
        span {
          display: block;
          margin-bottom: $spacing--small;
        }
      }

      .marketplace-button {
        float: right;
        margin-left: $spacing--small;

        @media (max-width: $tablet) {
          margin: $spacing--tiny auto 0;
          text-align: center;
          float: none;
          display: block;
          width: 50% !important;
          max-width: 200px;
        }
      }
    }

    &__settings {
      margin-bottom: $spacing--small;
      font-size: 14px;
    }


    &__product {
      display: grid;
      grid-template-columns: 1fr 3fr;
      column-gap: $spacing--small;
      font-size: 16px;
      font-weight: bold;
      margin: $spacing--small 0;

      img {
        max-width: 100%;
        max-height: 50px;
      }
    }

    &__error {
      color: $alert-color;
      margin-bottom: 8px;
    }
  }

  .modal-show-enter-active {
    animation: slide_in_modal .3s ease-in-out;
  }
  .modal-show-leave-active {
    animation: slide_out_modal .3s ease-in-out;
  }

  @keyframes slide_in_modal {
    0% {
      opacity:0;
      transform: translateX(-70%);
    }
    100% {
      transform: translateX(0%);
      opacity:1;
    }
  }

  @keyframes slide_out_modal {
    0% {
      transform: translateX(0%);
      opacity:1;
    }
    100% {
      transform: translateX(-70%);
      opacity:0;
    }
  }
</style>
